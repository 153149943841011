import { createGlobalStyle } from 'styled-components';
import PropaneSVGBackground from '../images/the-propane-home-bg-en.jpg';

const SlpGlobalStyles = createGlobalStyle`
/* This is used to test if the stylesheet has been loaded yet*/
		
/*------------------------------
    1. GENERAL 
------------------------------*/
	
	* {
		box-sizing: border-box;		
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-text-size-adjust: none;
		}
		
	body {
		margin: 0;
		padding: 0;
		color: #661988;
		/*background: #f7f7f7;*/
		background: #fff url('../images/bg.jpg') no-repeat top center;
		font-family: 'Figtree-Regular', arial, sans-serif;
		font-size: 14px;
		line-height: 130%;
		letter-spacing: 0.02em;
		}
	
	img {
		border: 0;
		vertical-align: top;
		}
	
	h1 {	
		margin: 0 0 35px 0;
		padding: 0;		
		font-family: 'Figtree-Medium', arial, sans-serif;
		font-weight: normal !important;
		color: #661988;
		font-size: 34px;
		line-height: 36px;
		}
	h2 {			
		margin: 0 0 35px 0;
		padding: 0;		
		font-family: 'Figtree-Light', arial, sans-serif;
		font-weight: normal !important;
		color: #661988;
		font-size: 28px;
		line-height: 38px;
		text-align:center;
		}		
	h3 {			
		margin: 0 0 5px 0;
		padding: 0;		
		font-family: 'Figtree-Regular', arial, sans-serif;
		font-weight: normal !important;
		color: #661988;
		font-size: 18px;
		line-height: 20px;
		}
	h4 {			
		margin: 0 0 25px 0;
		padding: 0 60px;		
		font-family: 'Figtree-Regular', arial, sans-serif;
		font-weight: normal !important;
		color: #661988;
		font-size: 23px;
		line-height: 130%;
		}
		
	h5 {
		margin: 0 0 35px 0;
		padding: 0;		
		font-family: 'Figtree-Regular', arial, sans-serif;
		font-weight: normal !important;
		color: #661988;
		font-size: 34px;
		line-height: 38px;
		text-align:center;
	}
	
	h6 {
		margin: 0 0 10px 0;
		padding: 0;		
		font-family: 'Figtree-Medium', arial, sans-serif;
		font-weight: normal !important;
		color: #646464;
		font-size: 18px;
		line-height: 20px;
		
	}
			
	hr {
		height: 1px;
		border: none;
		background: #e5e5e5;
		margin: 50px 0;
		clear: both;
		}
		
	p {		
		margin: 0 0 20px 0;
		padding: 0;
		text-align: center;
		}	
		p.note {		
			font-size: 14px;
			}
		
	strong {
		font-family: 'Figtree-Bold', arial, sans-serif;
		}
		
	a:hover, a:active, a:focus, object { 
		outline: none; 
		}			
	a {
		text-decoration: none;
		color: #944fb2;	
		webkit-transition: color .15s ease-out;
		-moz-transition: color .15s ease-out;
		-o-transition: color .15s ease-out;
		-ms-transition: color .15s ease-out;
		transition: color .15s ease-out;
		}
	a:hover {
		text-decoration: none;
		color: #661988;
		}

	form, 
	input,
	textarea {
		margin: 0;
		padding: 0;
		outline: none;
		-webkit-border-radius:0; 
		}

	sup,
	sub {
		font-size: 18px;
		line-height: 18px;
		vertical-align: top;
		}
	
	.clear {
		clear: both;
		}
	.clearfix:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
		}
	
	
	/* BUTTONS */
	
	.button {
		display: inline-block;		
		padding: 16px 85px 14px 15px;
		margin: 0 5px 5px 0;
		font-family: 'Figtree-Medium', arial, sans-serif;
		font-size: 13px;
		line-height: 16px;
		text-transform: uppercase !important;
		text-align: left;
		color: #037ec4;
		background: #ebf5fa url('../images/arrow-blue-small.png') no-repeat 99% center;
		background-size: 23px auto;
		-webkit-transition: background-position 0.15s ease-out;
		-moz-transition: background-position 0.15s ease-out;
		-o-transition: background-position 0.15s ease-out;
		-ms-transition: background-position 0.15s ease-out;
		transition: background-position 0.15s ease-out;
		border: none;
		cursor: pointer;
		}
	.button:hover {
		background-position: 95% center;
		}

.propaneHome{
	.st0{fill:#3A2417;}
	.st1{fill:#FFFFFF;}
	.st2{fill: #00493B;}
	.svgTextContainer{
		:hover{
			cursor: pointer;
			.st2{fill: #FFC429;};
			}
	}
	svg{
		display: block;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: auto;
		margin: 0 auto;
		background: url(${PropaneSVGBackground}) no-repeat center left;
		background-size: 100% auto;
		:focus{
			outline: none;
		}
	
		@media (max-width: 767px){
			width: 100%;
			position: static;
		}
	}
}

@media (max-width: 767px){
	.tooltip-furnace-boiler{
		transform: translate(-20%, -40%);
	}
	.tooltip-water-heater{
		transform: translate(5%,-30%);
	}
	.tooltip-bbq{
		transform: translate(-35%,-15%);	
	}
	.tooltip-generator{
		transform: translate(-30%, 25%);	
	}
	.tooltip-heat-pump{
		transform: translate(0%, -20%);
	}
	.tooltip-gas-range{
		transform: translate(-20%, -20%);
	}
}

.propaneHome-tooltip {
	/* width: 425px;
    padding: 40px;
	font-size: 16px;
    line-height: 22px; */
	width: 250px;
    font-size: 9px;
    line-height: 12px;
    padding: 20px;
    background: #00493B;
    border-radius: 6px;
	text-align: center;
	font-family: 'HelveticaNeue-Light', arial, sans-serif;
    margin-bottom: 0;
    color: #fff;
	@media (max-width: 767px){
		width: 425px;
    	font-size: 16px;
    	line-height: 22px;
	}
	@media (max-width: 575px){
		width: 425px;
    	font-size: 24px;
    	line-height: 26px;
	}
}
`;
export default SlpGlobalStyles;
