import { graphql } from 'gatsby';
import * as React from 'react';
import loadable from '@loadable/component';
import { sectionList } from '../../constants/queryFragments';
import SlpGlobalStyles from '../../styles/slpGlobalStyles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LogoOG from '../../images/logo-og.jpg';

const StandalonePageTemplate = ({ data }) => {
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const sectionsArray =
    data?.allContentfulColumbiaFuel?.edges[0]?.node?.sections;
  
  if (typeof window === 'undefined') {
    return null;
  } else {
      return (
      <React.Fragment>
        <SlpGlobalStyles />
        {sectionsArray &&
          sectionsArray.map((section, index) => {
            if (section?.type && sectionList.includes(section?.type)) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section.type}/${section.type}`)
              );
              return <SectionComponent key={index} sectionData={section} />;
            }
            return <></>;
          })}
      </React.Fragment>
    );
  };
};

export default StandalonePageTemplate;

export const pageQuery = graphql`
  query allStandalonePagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulImageWithPath: Boolean!
    $contentfulContentList: Boolean!
    $contentfulImageContainer: Boolean!
    $contentfulListImagesWithPath: Boolean!
  ) {
    allContentfulColumbiaFuel(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          hasIndex
          metadata
          title
          node_locale
          sections {
            __typename
            ...ImageWithPath @include(if: $contentfulImageWithPath)
            ...PageContent @include(if: $contentfulContent)
            ...ContentList @include(if: $contentfulContentList)
            ...ImageContainer @include(if: $contentfulImageContainer)
            ...ImagesWithPathList @include(if: $contentfulListImagesWithPath)
          }
        }
      }
    }
  }
`;
